import {NumericFormat} from "react-number-format";
import React, {useState} from "react";
import moment from "moment";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {ToastContainer, toast, Flip} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {setCheckedInputs} from "../redux/reducers/business";


const BusinessList = ({state, onRefresh}) => {
    const dispatch = useDispatch()
    const [memo, setMemo] = useState("");
    let [toastState, setToastState] = useState(false);
    const userInfo = useSelector((state) => state.userInfo);

    const receiveChangeState = (receive, caller, userNo) => {
        axios.post('/team/business/receive_change_state', {
            receive: receive,
            caller: caller,
            admin_no: userInfo.userNo,
            user_no: userNo
        }).then((res) => {
            if (res.data.isUpdated) {
                toast.success(`${userNo}번호 상태변경 처리완료!`, {
                    transition: Flip
                })
                onRefresh()
                //window.location.reload()
            } else {
                alert('처리실패')
            }
        })
    }

    const onMemoHandler = (e) => {
        setMemo(e.currentTarget.value)
    }
    const updateMemo = (memo, userNo) => {
        axios.post('/team/business/update_memo', {
            memo: memo,
            admin_no: userInfo.userNo,
            user_no: userNo
        }).then((res) => {
            if (res.data.isUpdated) {
                toast.success(`${userNo}번호 메모저장완료!`, {
                    transition: Flip
                })
                onRefresh()
                setMemo("")
                //onRefresh()
            } else {
                alert('저장실패')
            }
        })
    }

    const allCheckHandler = (e) => {
        const checkNos = []
        for(let index = 0; index < state.memberList.length; index++) {
            checkNos.push(state.memberList[index].no)
        }
        dispatch(setCheckedInputs(e.target.checked? checkNos : []));

    }
    const checkHandler = (checked, no) => {
        if(checked) {
            dispatch(setCheckedInputs([...state.checkedInputs, no]));
        }
        else {
            dispatch(setCheckedInputs(state.checkedInputs.filter((el) => el !== no)));
        }
    }

    const receiveList = [
        {no: 1, value: 1, name: "대기", color: "#000000"},
        {no: 2, value: 2, name: "부재중", color: "#C9AE00"},
        {no: 3, value: 3, name: "통화완료", color: "#22741C"},
        {no: 4, value: 4, name: "문자발송", color: "#0054FF"},
        {no: 5, value: 5, name: "바로끊음", color: "#5D5D5D"},
        {no: 6, value: 6, name: "나중에전화", color: "#5F00FF"},
        {no: 7, value: 7, name: "결번/정지", color: "#980000"},
        {no: 8, value: 8, name: "기존", color: "#CC723D"},
    ]

    return <div>
        <div className="content border excel_body">
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="dark"
            />
            <div className="d-flex ">
                <button className="btn btn-sm btn-danger mx-1" onClick={() => {
                    receiveChangeState(4, userInfo.id, state.checkedInputs)
                }}>선택문자발송처리</button>
            </div>
            <div className="d-flex ">
                <table className="table table-bordered table-sm text-center align-middle mt-2 member_table">
                    <thead className="table-dark">
                    <tr>
                        {<th style={{width: "30px"}}>
                            <input className="form-check-input" type="checkbox" checked={state.checkedInputs.length === state.memberList.length}
                                   onChange={(e) => allCheckHandler(e)} id="flexCheckDefault"/>
                        </th>}
                        <th style={{width: "100px"}}>번호</th>
                        <th>목록명</th>
                        <th>연락처</th>
                        <th>메모</th>
                        <th style={{width: "6%"}}>상태</th>
                        <th>할당일</th>
                        <th>수정일</th>

                    </tr>
                    </thead>
                    {state.memberList && state.memberList.map((file, index) => (
                        <tbody key={file.no}>
                        <tr style={{
                            backgroundColor: file.receive === 1? "#FFFFFF" : receiveList[file.receive - 1].color + "33"
                        }}>
                            {<td>
                                <input className="form-check-input" type="checkbox"
                                       checked={state.checkedInputs.includes(file.no)}
                                       onChange={(e) => checkHandler(e.currentTarget.checked, file.no, index)} />
                            </td>}
                            <td style={{fontSize: "13px"}}>{file.no}</td>
                            <td style={{fontSize: "13px"}}>{file.businessDBList.businessDBTitle.title}</td>
                            <td onClick={() => {
                                navigator.clipboard.writeText(file.member_upload.phone)
                                toast.success(`${file.member_upload.phone} 복사완료!`, {
                                    transition: Flip
                                })
                            }}>
                                <p>{file.member_upload.phone}</p>
                            </td>
                            <td>
                                <div className="input-group my-auto">
                                    <input type="text" className="form-control" aria-label="Recipient's username" aria-describedby="button-addon2"
                                           maxLength="300" placeholder="내용" onChange={onMemoHandler} defaultValue={file.memo}/>
                                    <button className="btn btn-success" type="button" id="button-addon2"
                                            onClick={() => updateMemo(memo, file.no)}>
                                        저장
                                    </button>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex">
                                    <select className="form-select" onChange={(e) => {
                                        receiveChangeState(parseInt(e.currentTarget.value), userInfo.id, file.no)
                                    }} value={file.receive} style={{
                                        width: "150px",
                                        color: receiveList[file.receive - 1].color
                                    }}>
                                        {receiveList.map((item) => (
                                            <option key={item.no} value={item.value} style={{
                                                color: item.color
                                            }}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </td>
                            <td>{moment(file.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                            <td>{moment(file.updatedAt).format("YYYY-MM-DD HH:mm")}</td>

                        </tr>
                        </tbody>
                    ))}
                </table>
            </div>
        </div>
    </div>


}

export default BusinessList